import React from 'react';
import 'video.js/dist/video-js.css';

const LazyFireworks = (props) => {
  const [Component, setComponent] = React.useState(() => () => null);

  React.useEffect(() => {
    import('./Fireworks.js').then((Thing) =>
      setComponent(() => Thing.default)
    );
  }, []);

  return <Component {...props} />;
};

export default LazyFireworks;
