import React from 'react';
import styled from 'styled-components';

import useHasMounted from '../../hooks/use-has-mounted.hook.js';

function ClientOnly({ children, ...delegated }) {
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return null;
  }

  return <Wrapper {...delegated}>{children}</Wrapper>;
}

const Wrapper = styled.div`
  display: contents;
`;

export default ClientOnly;
