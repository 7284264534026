import React from 'react';
import styled from 'styled-components';

const Slider = React.forwardRef(
  (
    {
      value,
      onChange,
      handleSize = 16,
      trackSize = 3,
      backgroundColor = 'var(--color-background)',
      trackColor = 'var(--color-gray-300)',
      activeHandleFill = 'linear-gradient(0deg,hsl(35deg, 100%, 50%),hsl(55deg, 100%, 70%))',
      ...delegated
    },
    ref
  ) => {
    return (
      <Input
        ref={ref}
        type="range"
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        handleSize={handleSize}
        trackSize={trackSize}
        trackColor={trackColor}
        backgroundColor={backgroundColor}
        activeHandleFill={activeHandleFill}
        {...delegated}
      />
    );
  }
);

const Input = styled.input`
  display: block;
  width: 100%;
  background: transparent;
  appearance: none;
  outline-offset: 6px;
  cursor: pointer;

  &::-webkit-slider-thumb {
    appearance: none;
    height: ${(p) => p.handleSize}px;
    width: ${(p) => p.handleSize}px;
    border-radius: 50px;
    background: white;
    cursor: grab;
    transform: translateY(calc(-50% + ${(p) => p.trackSize / 2}px));
    outline: 2px solid ${(p) => p.backgroundColor};

    &:active {
      cursor: grabbing;
    }
  }

  &:focus::-webkit-slider-thumb {
    background: ${(p) => p.activeHandleFill};
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: calc(100% - ${(p) => p.handleSize}px);
    height: ${(p) => p.trackSize}px;
    background: ${(p) => p.trackColor};
    border-radius: ${(p) => p.trackSize / 2}px;
    margin: ${(p) => p.handleSize / 2}px 0;
  }
  &::-moz-range-track {
    background: ${(p) => p.trackColor};
  }
`;

export default Slider;
