import React from 'react';
import styled from 'styled-components';

import MaxWidthWrapper from '../MaxWidthWrapper';
import Logo from '../Logo';
import Link from '../Link';

const Footer = ({ background, overscrollColor }) => {
  return (
    <Wrapper
      role="contentinfo"
      style={{
        background,
      }}
    >
      <InnerWrapper maxWidth={900}>
        <Left>
          <Top>
            <Logo />
          </Top>
          <DesktopOnly>
            <Copyright>
              © 2020-present Joshua Comeau. All Rights Reserved.
            </Copyright>
          </DesktopOnly>
        </Left>
        <Right>
          <Column>
            <Heading>Links</Heading>
            <OneColumnChildren>
              <FooterLink href="https://courses.joshwcomeau.com/terms">
                Terms of Use
              </FooterLink>
              <FooterLink href="https://courses.joshwcomeau.com/privacy">
                Privacy Policy
              </FooterLink>
              <FooterLink href="https://www.joshwcomeau.com/contact/">
                Contact
              </FooterLink>
              <FooterLink href="https://twitter.com/joshwcomeau">
                Twitter
              </FooterLink>
            </OneColumnChildren>
          </Column>
        </Right>
        <MobileOnly>
          <MobileCopyright>
            © 2020-present Joshua Comeau. All Rights Reserved.
          </MobileCopyright>
        </MobileOnly>
      </InnerWrapper>
    </Wrapper>
  );
};

const DesktopOnly = styled.span`
  @media (max-width: 725px) {
    display: none;
  }
`;
const MobileOnly = styled.span`
  @media (min-width: 726px) {
    display: none;
  }
`;

const Wrapper = styled.footer`
  position: relative;
  color: var(--color-gray-700);
`;

const InnerWrapper = styled(MaxWidthWrapper)`
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 32px;

  @media (max-width: 725px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 725px) {
    text-align: center;
    padding-bottom: 32px;
  }
`;
const Right = styled.div`
  display: flex;

  @media (max-width: 725px) {
    text-align: center;
  }
`;

const Column = styled.div`
  margin-left: 96px;
  padding-top: 8px;

  @media (max-width: 725px) {
    margin-left: 0;
  }
`;

const FooterLink = styled(Link)`
  font-size: 14px;
  font-weight: var(--font-weight-light);
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Heading = styled.h3`
  font-size: 14px;
  font-weight: var(--font-weight-light);
  color: var(--color-gray-700);
  margin-bottom: 0;
`;

const OneColumnChildren = styled.div`
  display: grid;
  width: 100px;
  grid-template-columns: 1fr;
  grid-gap: 6px;
  padding-top: 12px;
`;

const Top = styled.div``;

const Copyright = styled.div`
  font-size: 12px;
  color: var(--color-gray-700);
`;

const MobileCopyright = styled(Copyright)`
  padding-top: 48px;
`;

export default Footer;
