import React from 'react';
import styled from 'styled-components';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import Footer from '@components/Footer';
import Spacer from '@components/Spacer';
import SEO from '@components/SEO';
import Em from '@components/Em';
import Slider from '@components/Slider';
import ClientOnly from '@components/ClientOnly';
import Fireworks from '@components/Fireworks/LazyFireworks';

export default function ConfirmedPage() {
  const [fireworksAmount, setFireworksAmount] = React.useState(25);
  return (
    <Wrapper>
      <MaxWidthWrapper>
        <ClientOnly>
          <Fireworks volume={fireworksAmount} />
        </ClientOnly>
        <Content>
          <Title>Email Confirmed!</Title>
          <p>
            A couple years back, I had a realization that changed my
            understanding of CSS. Ever since then, the language has
            made more sense to me.
          </p>
          <p>
            That realization is the subject of the first email in this
            free preview. And it should be arriving in your inbox any
            moment now!
          </p>
          <p>
            If you don't see it after a couple of minutes, and it's
            not hiding in your <Em color="inherit">“Promotions”</Em>{' '}
            tab / spam folder, please{' '}
            <a href="mailto:support@joshwcomeau.com">let me know</a>,
            and I'll make sure you get it.
          </p>
          <Spacer $size={64} />
          <SliderWrapper>
            <label htmlFor="fw-slider">Fireworks Intensity:</label>
            <Slider
              id="fw-slider"
              min={0}
              max={100}
              value={fireworksAmount}
              onChange={setFireworksAmount}
            />
          </SliderWrapper>

          <ReducedMotionExplanation>
            (You would normally be seeing fireworks in the background,
            but they've been disabled because you've toggled the
            “Reduce Motion” option in your accessibility settings.)
          </ReducedMotionExplanation>
        </Content>
        <Spacer $size={192} />
      </MaxWidthWrapper>
      <Footer />
      <SEO />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: min(250px, 10vh);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled.main`
  --color-primary: var(--color-tertiary);
  position: relative;

  p {
    font-size: 1.25rem;
    filter: drop-shadow(0px 0.5px 1px hsl(274deg 16% 8% / 0.5))
      drop-shadow(0px 1px 2px hsl(274deg 16% 8% / 0.5))
      drop-shadow(0px 2px 4px hsl(274deg 16% 8% / 0.5))
      drop-shadow(0px 4px 8px hsl(274deg 16% 8% / 0.5));
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 3rem;
  text-align: center;
  filter: drop-shadow(0px 1px 2px hsl(274deg 16% 8% / 0.5))
    drop-shadow(0px 2px 4px hsl(274deg 16% 8% / 0.5))
    drop-shadow(0px 4px 8px hsl(274deg 16% 8% / 0.5))
    drop-shadow(0px 8px 16px hsl(274deg 16% 8% / 0.5));
`;

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 260px;
  margin: 0 auto;
  filter: drop-shadow(0px 0.5px 1px hsl(274deg 16% 8% / 0.5))
    drop-shadow(0px 1px 2px hsl(274deg 16% 8% / 0.5))
    drop-shadow(0px 2px 4px hsl(274deg 16% 8% / 0.5))
    drop-shadow(0px 4px 8px hsl(274deg 16% 8% / 0.5));

  label {
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
  }
`;

const ReducedMotionExplanation = styled.p`
  display: none;
  margin-top: 48px;

  && {
    font-size: 1rem;
  }

  @media (prefers-reduced-motion: reduce) {
    display: block;
  }
`;
