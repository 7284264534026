import styled from "styled-components";

export default styled.em`
  font-family: "Sriracha";
  letter-spacing: -0.25px;
  color: ${(props) => props.color || "var(--color-primary)"};
  font-style: normal;

  .tippy-popper & {
    color: var(--color-background) !important;
  }
`;
